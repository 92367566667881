import mm3Logo from "../common-utilities/component-mm3-logo";

class MenuLink extends HTMLElement {
  constructor() {
    super();
    this.customtitle =
      this.getAttribute("customtitle") === "undefined"
        ? "Powered by Media Manager"
        : this.getAttribute("customtitle");
    this.customimage =
      this.getAttribute("customimage") === "undefined"
        ? mm3Logo()
        : this.getAttribute("customimage");
    this.customurl =
      this.getAttribute("customurl") === "undefined"
        ? "https://www.mediamanager.io"
        : this.getAttribute("customurl");
    this.showTooltip =
      this.getAttribute("showtooltip") === "true" ? true : false;
  }
  static css = `
    :host {
      position: relative;
    }
    a {
      all: unset;
      display: flex;
      padding: 0 0.5rem;
      height: 100%;
      margin: auto;

      > img {
          transition: transform 0.25s;
      }
    }
    a:hover {
      cursor: pointer;

      > img {
        transform: scale(1.2);
      }
    }
    a > img {
      width: 24px;
      height: 24px;
      margin: auto;
    }
    a media-tooltip {
      display: none
    }
    a:hover media-tooltip {
      display: block;
    }
  `;

  connectedCallback() {
    const buttonHtml = `
          <a href="${this.customurl}" title="${this.customtitle}">
            ${this.showTooltip ? `<media-tooltip>${this.customtitle}</media-tooltip>` : ""}
            <img src="${this.customimage}" width="24" alt="" />
          </a>
        `;
    const shadowroot = this.attachShadow({ mode: "open" });
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(MenuLink.css);
    shadowroot.adoptedStyleSheets = [sheet];

    shadowroot.innerHTML = buttonHtml;
  }
}

customElements.define("menu-link", MenuLink);

export default MenuLink;
