/**
 *
 * @param {string | null} [className] - the class name to be added to the component
 * @returns {string} - the html string to be rendered
 */
function composeMuteButton(className) {
  return `
    <media-mute-button ${className ? `class="${className}" noautohide` : ""} notooltip>
      <svg slot="high" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.1481 12.2662C20.1481 10.5935 19.6882 9.08264 18.7686 7.73375C17.8488 6.38505 16.6358 5.39588 15.1297 4.76625C14.889 4.66125 14.7161 4.49616 14.6111 4.27097C14.5061 4.0456 14.4999 3.81875 14.5925 3.59042C14.6914 3.34338 14.8658 3.17051 15.1158 3.0718C15.3658 2.9731 15.6205 2.97616 15.8797 3.08097C17.7253 3.85264 19.2068 5.06718 20.3242 6.72458C21.4414 8.38199 22 10.2292 22 12.2662C22 14.3033 21.4414 16.1505 20.3242 17.8079C19.2068 19.4653 17.7253 20.6799 15.8797 21.4515C15.6205 21.5563 15.3658 21.5594 15.1158 21.4607C14.8658 21.362 14.6914 21.1891 14.5925 20.9421C14.4999 20.7138 14.5061 20.4869 14.6111 20.2615C14.7161 20.0363 14.889 19.8713 15.1297 19.7663C16.6358 19.1366 17.8488 18.1475 18.7686 16.7987C19.6882 15.4499 20.1481 13.939 20.1481 12.2662ZM6.44444 15.6274H2.92583C2.66046 15.6274 2.43981 15.5394 2.26389 15.3635C2.08796 15.1875 2 14.9669 2 14.7015V9.88653C2 9.62116 2.08796 9.40051 2.26389 9.22458C2.43981 9.04866 2.66046 8.96069 2.92583 8.96069H6.44444L10.4258 4.9793C10.716 4.68912 11.0509 4.62273 11.4306 4.78014C11.8102 4.93755 12 5.22301 12 5.63653V18.9515C12 19.365 11.8102 19.6505 11.4306 19.8079C11.0509 19.9653 10.716 19.8989 10.4258 19.6088L6.44444 15.6274ZM17 12.294C17 13.1953 16.7808 14.021 16.3425 14.771C15.9044 15.521 15.2994 16.1058 14.5278 16.5254C14.3672 16.6119 14.2144 16.6073 14.0694 16.5115C13.9244 16.416 13.8519 16.2817 13.8519 16.1087V8.44208C13.8519 8.26931 13.9244 8.13505 14.0694 8.03931C14.2144 7.94375 14.3672 7.93912 14.5278 8.02542C15.2994 8.45134 15.9044 9.04708 16.3425 9.81264C16.7808 10.578 17 11.4051 17 12.294Z" fill="currentColor"/>
      </svg>
      <svg slot="low" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.44444 15.6274H2.92583C2.66046 15.6274 2.43981 15.5394 2.26389 15.3635C2.08796 15.1876 2 14.9669 2 14.7015V9.88654C2 9.62117 2.08796 9.40052 2.26389 9.22459C2.43981 9.04867 2.66046 8.9607 2.92583 8.9607H6.44444L10.4258 4.97931C10.716 4.68913 11.0509 4.62274 11.4306 4.78015C11.8102 4.93756 12 5.22302 12 5.63654V18.9515C12 19.3651 11.8102 19.6505 11.4306 19.8079C11.0509 19.9653 10.716 19.8989 10.4258 19.6088L6.44444 15.6274ZM17 12.294C17 13.1953 16.7808 14.021 16.3425 14.771C15.9044 15.521 15.2994 16.1058 14.5278 16.5254C14.3672 16.6119 14.2144 16.6073 14.0694 16.5115C13.9244 16.416 13.8519 16.2817 13.8519 16.1088V8.44209C13.8519 8.26932 13.9244 8.13506 14.0694 8.03932C14.2144 7.94376 14.3672 7.93913 14.5278 8.02543C15.2994 8.45135 15.9044 9.04709 16.3425 9.81265C16.7808 10.578 17 11.4051 17 12.294Z" fill="currentColor"/>
      </svg>
      <svg slot="medium" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.44444 15.6274H2.92583C2.66046 15.6274 2.43981 15.5394 2.26389 15.3635C2.08796 15.1876 2 14.9669 2 14.7015V9.88654C2 9.62117 2.08796 9.40052 2.26389 9.22459C2.43981 9.04867 2.66046 8.9607 2.92583 8.9607H6.44444L10.4258 4.97931C10.716 4.68913 11.0509 4.62274 11.4306 4.78015C11.8102 4.93756 12 5.22302 12 5.63654V18.9515C12 19.3651 11.8102 19.6505 11.4306 19.8079C11.0509 19.9653 10.716 19.8989 10.4258 19.6088L6.44444 15.6274ZM17 12.294C17 13.1953 16.7808 14.021 16.3425 14.771C15.9044 15.521 15.2994 16.1058 14.5278 16.5254C14.3672 16.6119 14.2144 16.6073 14.0694 16.5115C13.9244 16.416 13.8519 16.2817 13.8519 16.1088V8.44209C13.8519 8.26932 13.9244 8.13506 14.0694 8.03932C14.2144 7.94376 14.3672 7.93913 14.5278 8.02543C15.2994 8.45135 15.9044 9.04709 16.3425 9.81265C16.7808 10.578 17 11.4051 17 12.294Z" fill="currentColor"/>
      </svg>
      <svg slot="off" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.92583 15.6273H6.44444L10.4258 19.6087C10.716 19.8989 11.0509 19.9653 11.4306 19.8079C11.8102 19.6505 12 19.365 12 18.9515V5.63651C12 5.22299 11.8102 4.93752 11.4306 4.78012C11.0509 4.62271 10.716 4.6891 10.4258 4.97928L6.44444 8.96067H2.92583C2.66046 8.96067 2.43981 9.04864 2.26389 9.22456C2.08796 9.40049 2 9.62114 2 9.88651V14.7015C2 14.9669 2.08796 15.1875 2.26389 15.3635C2.43981 15.5394 2.66046 15.6273 2.92583 15.6273ZM15.587 15.731L18.0001 13.2993L20.413 15.7124C20.5925 15.8919 20.8121 15.9816 21.072 15.9816C21.3319 15.9816 21.5515 15.8919 21.731 15.7124C21.9103 15.5329 22 15.3164 22 15.0628C22 14.8091 21.9103 14.5925 21.731 14.413L19.2993 12.0001L21.7124 9.58701C21.8919 9.40752 21.9816 9.18784 21.9816 8.92798C21.9816 8.66811 21.8919 8.44853 21.7124 8.26922C21.5329 8.08973 21.3164 7.99998 21.0628 7.99998C20.8091 7.99998 20.5925 8.08973 20.413 8.26922L18.0001 10.7007L15.587 8.2876C15.4075 8.10829 15.1879 8.01864 14.928 8.01864C14.6681 8.01864 14.4485 8.10829 14.2692 8.2876C14.0897 8.46709 14 8.6837 14 8.93744C14 9.191 14.0897 9.40752 14.2692 9.58701L16.7007 12.0001L14.2876 14.413C14.1083 14.5925 14.0187 14.8121 14.0187 15.072C14.0187 15.3319 14.1083 15.5515 14.2876 15.731C14.4671 15.9103 14.6837 16 14.9375 16C15.191 16 15.4075 15.9103 15.587 15.731Z" fill="currentColor"/>
      </svg>
      ${className === "unmute" ? '<span class="overlay-muted" slot="off">Unmute</span>' : ""}
    </media-mute-button>
`;
}

export default composeMuteButton;
