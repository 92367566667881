/**
 * @param {string} [aspectRatio] - string representation of the aspect ratio
 * @returns {string} - the formatted aspect ratio
 */
function formatAspectRatio(aspectRatio) {
  const defaultAspectRatio = "16 / 9";
  if (!aspectRatio) {
    return defaultAspectRatio;
  }
  if (aspectRatio.includes("/")) {
    return aspectRatio;
  }
  if (!aspectRatio.includes(":")) {
    return defaultAspectRatio;
  }
  const [width, height] = aspectRatio.split(":");
  return `${width} / ${height}`;
}
export default formatAspectRatio;
