import formatAspectRatio from "../common-utilities/format-aspect-ratio";
/**
 * @typedef {import('../../types').UserOptions} UserOptions
 * @typedef {import('../../types').Content} Content
 */

/**
 *
 * @param {UserOptions | any} userOptions - user settings passed into the component. Having to include `any` because there's no easy way of passing only attributes of the class without explicity stating what they are
 * @param {Content} mediaItem - the type of media item
 * @returns {string} - the html string to be rendered
 */
function composeVideoCss(userOptions, mediaItem) {
  const aspectRatio = formatAspectRatio(
    userOptions.aspectratio ?? mediaItem.media?.aspect_ratio ?? null,
  );

  const overrideColor = userOptions.overridecolor
    ? userOptions.overridecolor
    : mediaItem.player?.color;

  return `
    :host {
      --primary-600: ${overrideColor ?? `#0ea5e9`};
      --media-button-icon-width: 24px;
      --media-font-size: 14px;
      --zinc-20: #E0E0E0;
      --zinc-60: #5A5A5A;
      --zinc-80: #333333;
      --zinc-90: rgba(0, 0, 0, 0.75);
      display: block;
    }
    media-controller {
      --media-range-thumb-background: var(--primary-600);
      --media-control-background: transparent;
      --media-control-hover-background: transparent;
      --media-control-padding: 8px;
      --media-webkit-text-track-transition: transform 0.15s ease;
      --media-font-weight: 400;
      --media-tooltip-background: var(--zinc-90);
      --media-preview-chapter-background: rgba(0, 0, 0, 0.9);
      --media-preview-time-background: rgba(0, 0, 0, 0.9);
      --media-preview-chapter-text-shadow: 0;
      --media-preview-time-text-shadow: 0;
      --media-preview-time-padding: 0.2rem 0rem 0.5rem;
      position: relative;
      display: block;
      ${userOptions.height ? `height: ${userOptions.height};` : ""}
      ${userOptions.width ? `width: ${userOptions.width};` : "100%"}
      aspect-ratio: ${aspectRatio};
    }
    media-controller:hover,
    media-controller:is([mediapaused]) {
      --media-webkit-text-track-transform: translateY(-4rem);
    }
    .offline-notice {
      font-family: sans-serif;
      position: absolute;
      inset: 0;
      display: flex;
      aspect-ratio: 16 / 9;
    }
    .offline-notice > div {
      margin: auto;
      color: white;
      text-align: center;
    }
    .offline-notice > div > h2 {
      padding-bottom: 1rem;
    }
    .duration {
      font: var(--media-font, var(--media-font-weight, normal) var(--media-font-size, 14px) / var(--media-text-content-height, var(--media-control-height, 24px)) var(--media-font-family, helvetica neue, segoe ui, roboto, arial, sans-serif));
      color: var(--media-text-color, var(--media-primary-color, rgb(238 238 238)));
      background: var(--media-text-background, var(--media-control-background, var(--media-secondary-color, rgb(20 20 30 / .7))));
      padding: var(--media-control-padding, 10px);
      display: var(--media-control-display, var(--media-time-display-display, inline-flex));
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      box-sizing: border-box;
      text-align: center;
      pointer-events: auto;
    }
    .duration .before {
      margin-left: -0.80rem;
    }
    media-mute-button {
      border-radius: 4px;
      }
      media-mute-button.unmute {
        --media-control-background: var(--zinc-90);
      padding: 1rem 0.5rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 1;
      display: none;
      color: white;
    }
    .time-duration-display span {
      font-size: 12px;
      color: white;
      margin-left: -0.35rem;
      margin-right: -0.45rem;
    }
    media-mute-button:has(.overlay-muted) {
      flex-direction: row;
      min-width: 8rem;
    }
    media-mute-button:has(.overlay-muted) svg {
        width: 20px;
        margin-right: 1rem;
        margin-left: -1rem;
        min-width: 20px;
    }
    media-mute-button .overlay-muted {
      margin-right: -1rem;
    }
    media-mute-button.unmute:hover {
      background: var(--zinc-60);
    }
    media-mute-button.unmute[aria-label="unmute"] {
      display: flex;
    }
    .video-meta {
      padding: 1.5rem;
      color: white;
      font-family: sans-serif;
      position: absolute;
      top: 0;
      max-width: min(calc(100% - 12rem), 42rem);
      transition: opacity 0.5s;
    }
    .video-meta h1 {
      line-height: 1.2;
      font-size: 1.25rem;
      margin-top: 0;
      margin-bottom: 0.2rem;
      font-weight: normal;
    }
    .video-meta p {
      line-height: 1.4;
      font-size: 0.875rem;
      overflow: hidden;
      margin-top: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
    .skip-buttons {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
    }
    downloads-menu[hidden] {
      display: none;
    }
    media-settings-menu,
    media-captions-menu {
      position: absolute;
      z-index: 2;
      bottom: 4rem;
      right: 1rem;
    }
    media-settings-menu,
    media-captions-menu,
    media-playback-rate-menu {
      --media-menu-background: var(--zinc-90);
      --media-control-background: var(--zinc-90);
      border-radius: 0.25rem;
    }
    .timerange {
      z-index: 2;
      display: block;
      align-self: stretch;
      height: 0px;
      padding: 0.5rem;
    }
    .controls {
      z-index: 1;
      align-self: stretch;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem;
      position: relative;
    }
    .controls::before {
      content: "";
      display: block;
      left: 0;
      height: 5rem;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
    }
    .controls > div {
      display: flex;
    }
    .controls > div > * {
      position: relative;
    }
    .volume-controls {
      position: relative;
    }
    .volume-controls media-volume-range {
      position: absolute;
      top: -5.4rem;
      left: -2rem;
      transform: rotate(-90deg);
      display: none;
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
      background: var(--zinc-90);
    }
    media-mute-button:hover ~ media-volume-range,
    media-mute-button:focus ~ media-volume-range,
    media-volume-range:hover,
    media-volume-range:focus, {
      display: block;
    }
    .volume-controls media-volume-range[visible="true"] {
      display: block;
    }
    media-time-range {
      width: 100%;
      --media-range-thumb-width: 0px;
      --media-range-thumb-height: 0px;
      --media-range-track-color: rgba(255,255,255,0.4);
      --media-range-bar-color: var(--primary-600);
      --media-control-height: 0px;
    }
    media-time-range:hover {
      --media-range-thumb-width: 18px;
      --media-range-thumb-height: 18px;
    }
    overlay-button[hidden="true"] {
      display:none;
    }
    overlay-button[hidden="false"] {
      display:block;
    }
    menu-link {
      height: unset;
    }
    media-volume-range {
    --media-range-bar-color: var(--primary-600);
      display: none;
    }
    media-poster-image {
      --media-object-fit: cover;
    }
    media-settings-menu-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    media-play-button,
    media-seek-backward-button,
    media-seek-forward-button,
    media-time-display,
    media-mute-button:not(.unmute),
    media-settings-menu-button,
    media-cast-button,
    media-fullscreen-button,
    menu-button,
    media-captions-button,
    media-airplay-button,
    media-pip-button,
    media-captions-menu-button {
      svg {
        transition: transform 0.25s;
      }
    }
    media-play-button:hover,
    media-seek-backward-button:hover,
    media-seek-forward-button:hover,
    media-time-display:hover,
    media-mute-button:not(.unmute):hover,
    media-settings-menu-button:hover,
    media-cast-button:hover,
    media-fullscreen-button:hover,
    menu-button:hover
    media-captions-button:hover,
    media-airplay-button:hover,
    media-pip-button:hover,
    media-captions-menu-button:hover {
      background: transparent;
  
      svg {
        transform: scale(1.2);
      }
    }
    .time-duration-display {
      display: none;
    }
    media-loading-indicator {
      position: absolute;
    }
    media-play-button:not(.controls media-play-button) {
      transform: scale(2);
    }
    @media(min-width: 768px) {
      media-play-button:not(.controls media-play-button) {
        transform: scale(3);
      }
      .time-duration-display {
        display: block;
      }
      ${
        userOptions.canfloat === "true"
          ? `
        media-controller.is-floating {
          position: fixed;
          top: 2rem;
          right: 2rem;
          width: 30rem;
          z-index: 510; /* drupal menu bar is currently 501 */
        }
        media-controller.is-floating .time-duration-display {
          display: none;
        }
      `
          : ""
      }
  `;
}
export default composeVideoCss;
