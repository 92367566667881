/**
 * Compose the HTML for the no media found screen
 * @returns {string} - the html string to be rendered
 */
function composeNoMediaHtml() {
  return `
    <style>
      .mediamanager-no-media-found {
        aspect-ratio: 16 / 9;
        width: 100%;
        background-color: black;
        display: flex;
      }
      .mediamanager-no-media-found h1 {
        margin: auto;
        color: white;
        font-family: sans-serif;
      }
    </style>
    <div class="mediamanager-no-media-found">
      <h1>No media found</h1>
    </div>
  `;
}
export default composeNoMediaHtml;
