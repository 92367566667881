/**
 * @typedef {import('../../types').UserOptions} UserOptions
 */

/**
 * Get Environment
 * @param {string} environment - the environment parameter
 * @returns {{ env_key: string }} - the environment object
 */
function resolveEnvironment(environment) {
  const environments = {
    production: {
      env_key: import.meta.env.VITE_MUX_DATA_ENV_KEY_PRODUCTION,
    },
    staging: {
      env_key: import.meta.env.VITE_MUX_DATA_ENV_KEY_STAGING,
    },
    development: {
      env_key: import.meta.env.VITE_MUX_DATA_ENV_KEY_DEVELOPMENT,
    },
  };
  if (!Object.keys(environments).includes(environment)) {
    return environments.production;
  }
  return environments[environment];
}

export default resolveEnvironment;
