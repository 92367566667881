class MenuButton extends HTMLElement {
  constructor() {
    super();
    this.customtitle =
      this.getAttribute("customtitle") ?? "Powered by Media Manager";
    this.controls = this.getAttribute("controls") ?? null;
  }

  static css = `
    button {
      all: unset;
      display: inline-flex;
      padding: 0 0.5rem;
      height: 100%;
      margin: auto;
      color: white;
      align-items: center;
      justify-content: center;
    }
    button media-tooltip {
      display: none;
    }
    button:hover {
      cursor: pointer;
    }
    button:hover media-tooltip {
      display: block;
    }
  `;

  connectedCallback() {
    const buttonHtml = `
          <button title="${this.customtitle}" aria-controls="${this.controls}">
            <media-tooltip placement=top><slot name="tooltip-content">${this.customtitle}</slot></media-tooltip>
            <slot name="icon"></slot>
          </button>
        `;
    const shadowroot = this.attachShadow({ mode: "open" });
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(MenuButton.css);
    shadowroot.adoptedStyleSheets = [sheet];

    shadowroot.innerHTML = buttonHtml;

    this.bindEvents();
  }
  bindEvents() {
    this.shadowRoot?.querySelector("button")?.addEventListener("click", () => {
      this.parentElement
        ?.querySelector(`${this.controls}`)
        ?.toggleAttribute("hidden");
    });
  }
}

customElements.define("menu-button", MenuButton);

export default MenuButton;
