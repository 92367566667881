import "media-chrome";
import "@mux/mux-video";

import getAndValidateMedia from "./get-media";
import getChapters from "./get-chapters";
import composeNoMediaHtml from "./compose-no-media-html";
import composeVideoCss from "./compose-video-css";
import composeVideoHtml from "./compose-video-html";
import composeAudioCss from "./compose-audio-css";
import composeAudioHtml from "./compose-audio-html";
import waitForElement from "../common-utilities/wait-for-element";

/**
 * @typedef {import('../../types').Audio} Audio
 * @typedef {import('../../types').Video} Video
 * @typedef {import('../../types').Content} Content
 * @typedef {import('../../types').UserOptions} UserOptions
 *  @typedef {import('../../types').Entry} Entry
 */

class MediaManagerPlayer extends HTMLElement {
  constructor() {
    super();
    /**
     * @type {Partial<UserOptions>}
     */
    this.getAttributeNames().forEach((attr) => {
      this[attr] = this.getAttribute(attr);
    });
    /**
     * @property {string} mediaplaying - media-chrome attribute to detect if the video has been interacted with
     */
    this.mediaplaying = this.getAttribute("mediaplaying") ?? "false";
    /**
     * @property {string} token - The media token
     */
    this.token = this.getAttribute("token");
    /**
     * @property {string} host - url to fetch videos from
     */
    this.host =
      this.getAttribute("host") ??
      this.setAttribute("host", "https://app.mediamanager.io");
    /**
     * @property {string} playercode - type of embed the user has chosen
     */
    this.playercode =
      this.getAttribute("playercode") ?? this.setAttribute("playercode", "1");
    /**
     * @property {boolean} shouldautostart - start the video as the user loads the page
     */
    this.shouldautostart =
      this.getAttribute("shouldautostart") ??
      this.setAttribute("shouldautostart", "false");
    /**
     * @property {number | null} [width] - set a specific width for the video
     */
    this.width =
      this.getAttribute("width") ?? this.setAttribute("width", "100%");
    /**
     * @property {number | null} showpictureinpicturecontrols - picture in picture
     */
    this.showpictureinpicturecontrols =
      this.getAttribute("showpictureinpicturecontrols") ??
      this.setAttribute("showpictureinpicturecontrols", "true");
    /**
     * @property {string | null} showcontrols - show video chrome controls
     */
    this.showcontrols =
      this.getAttribute("showcontrols") ??
      this.setAttribute("showcontrols", "true");
    /**
     * @property {string} showmediamanagerlogo - Dispay the mediaManager logo
     */
    this.showmediamanagerlogo =
      this.getAttribute("showmediamanagerlogo") ??
      this.setAttribute("showmediamanagerlogo", "true");
    /**
     * @property {string} showtitle - Whether to display the title of your video inside your player.
     */
    this.showtitle =
      this.getAttribute("showtitle") ?? this.setAttribute("showtitle", "false");
    /**
     * @property {string} showdescription - Whether to display the description of your video inside your player.
     */
    this.showdescription =
      this.getAttribute("showdescription") ??
      this.setAttribute("showdescription", "false");
    /**
     * @property {string} showplaybackratecontrols - Whether to display a settings menu to adjust playback speed.
     */
    this.showplaybackratecontrols =
      this.getAttribute("showplaybackratecontrols") ??
      this.setAttribute("showplaybackratecontrols", "true");
    /**
     * @property {boolean} canbecast - Casting enables a viewer to use Google Cast or Apple AirPlay technologies to stream video and audio content to a compatible TV or sound system. By enabling the casting feature for a player, a viewer can tap an icon in the control bar to stream your content on a cast-compatible device. If no compatible device is detected by the player, no cast icon appears.
     */
    this.canbecast =
      this.getAttribute("canbecast") ?? this.setAttribute("canbecast", "true");
    /**
     * @property {boolean} canfloat - Keeps the player visible when the original player location is scrolled out of view by minimizing it to a corner of the screen.
     */
    this.canfloat =
      this.getAttribute("canfloat") ?? this.setAttribute("canfloat", "false");
    /**
     * @property {boolean} islikelive - Whether the media should look like it's a live stream
     */
    this.islikelive =
      this.getAttribute("islikelive") ??
      this.setAttribute("islikelive", "false");
    /**
     * @property {boolean} showsharingbuttons - When true displays a share button on the player for Twitter, Facebook, LinkedIn and Email.
     */
    this.showsharingbuttons =
      this.getAttribute("showsharingbuttons") ??
      this.setAttribute("showsharingbuttons", "false");
    /**
     * @property {boolean} isdownloadable - When true displays a share button on the player for Twitter, Facebook, LinkedIn and Email.
     */
    this.isdownloadable =
      this.getAttribute("isdownloadable") ??
      this.setAttribute("isdownloadable", "false");
    /**
     * @property {number} skipto - start the video at a specific timestamp
     */
    this.skipto =
      this.getAttribute("skipto") ?? this.setAttribute("skipto", "0");
    /**
     * @property {boolean} ismuted - Configures if the player should be muted during playback.
     */
    this.ismuted =
      this.getAttribute("ismuted") ?? this.setAttribute("ismuted", "false");
    /**
     * @property {boolean} skiprecap - Whether to display the skip recap buttons if configured on the video.
     */
    this.skiprecap =
      this.getAttribute("skiprecap") ?? this.setAttribute("skiprecap", "true");
    /**
     * @property {boolean} skipintro - Whether to display the skip intro buttons if configured on the video.
     */
    this.skipintro =
      this.getAttribute("skipintro") ?? this.setAttribute("skipintro", "true");
    /**
     * @property {string} aspectratio - override API aspect ratio
     */
    this.aspectratio = this.getAttribute("aspectratio");
    /**
     * @property {string} debug - print debug information from mux player
     */
    this.debug =
      this.getAttribute("debug") ?? this.setAttribute("debug", "false");
    /**
     * @property {string} debug - print debug information from mux player
     */
    this.key = this.getAttribute("key");
  }

  /**
   *
   * General methods
   * @param {Content} mediaItem - The media item
   */
  async bindEvents(mediaItem) {
    /**
     * @type {HTMLMediaElement | undefined | null}
     */
    const muxVideo = this.shadowRoot?.querySelector("mux-video");

    const userEvents = [
      "abort",
      "canplay",
      "canplaythrough",
      "durationchange",
      "emptied",
      "ended",
      "error",
      "loadeddata",
      "loadedmetadata",
      "loadstart",
      "pause",
      "play",
      "playing",
      "progress",
      "ratechange",
      "seeked",
      "seeking",
      "stalled",
      "suspend",
      "timeupdate",
      "volumechange",
      "waiting",
    ];

    userEvents.forEach((userEvent) => {
      muxVideo?.addEventListener(userEvent, () => {
        this.dispatchEvent(
          new CustomEvent(userEvent, { bubbles: true, composed: true }),
        );

        this.setAttribute("mediatime", muxVideo?.currentTime.toString());
      });
    });

    const incomingEvents = [
      "play",
      "pause",
      "mute",
      "seek",
      "emit",
      "getchapters",
    ];

    incomingEvents.forEach((incomingEvent) => {
      if (!muxVideo) {
        return;
      }
      /**
       * @param {MouseEvent} event - The mouse event
       */
      this?.addEventListener(incomingEvent, async (event) => {
        const customEvent = /** @type {CustomEvent} */ (event);
        if (customEvent.type === "getchapters") {
          if (!mediaItem.media?.tracks?.chapters) {
            this.dispatchEvent(
              new CustomEvent("chaptersdataupdated", {
                detail: { chaptersData: null },
                bubbles: true,
                composed: true,
              }),
            );
            return;
          }
          /**
           * @type {Entry[] | undefined | null}
           */
          const chaptersData = await getChapters(
            mediaItem.media.tracks.chapters,
          );

          this.dispatchEvent(
            new CustomEvent("chaptersdataupdated", {
              detail: { chaptersData },
              bubbles: true,
              composed: true,
            }),
          );
        }
        if (customEvent.type === "seek") {
          muxVideo.currentTime = customEvent.detail.timestamp;
        }
        if (customEvent.type === "play") {
          this.setAttribute("mediaplaying", "true");
          muxVideo.play();
        }
        if (customEvent.type === "pause") {
          this.setAttribute("mediapaused", "true");
          muxVideo.pause();
        }
        if (customEvent.type === "mute") {
          const isMuted = this.getAttribute("mediamuted");
          this.setAttribute(
            "mediamuted",
            isMuted === "true" ? "false" : "true",
          );
          muxVideo.muted = isMuted === "true" ? false : true;
        }
        if (customEvent.type === "emit") {
          if (!customEvent.detail?.key) {
            return;
          }
          // @ts-ignore type isn't available but this isn't a native element
          muxVideo?.nativeEl?.mux.emit("programchange", {
            [customEvent.detail.key]: customEvent.detail.value,
          });
        }
      });
    });
  }

  initiateDownload(downloads) {
    const downloadMenuButton =
      this.shadowRoot?.querySelector("#downloads-menu");

    if (!downloadMenuButton || !downloads) {
      return;
    }
    const downloadButton =
      downloadMenuButton.shadowRoot?.querySelector("button");
    downloadButton?.addEventListener("click", async () => {
      document.location.href = downloads[downloads.length - 1].url;
    });
  }
  async showControlsOnActivate(mediaItemType = "Video") {
    if (
      !this.shadowRoot ||
      this.showcontrols === "false" ||
      mediaItemType !== "Video"
    ) {
      return;
    }
    const controls = await waitForElement(this.shadowRoot, ".controls");

    if (controls && controls instanceof HTMLElement) {
      controls.style.display = "none";
    }
    const skipIntroButton = this.shadowRoot.querySelector(
      "button[title=skip-intro]",
    );

    if (skipIntroButton && skipIntroButton instanceof HTMLButtonElement) {
      skipIntroButton.style.display = "none";
    }
    const muteButton = this.shadowRoot.querySelector(
      "media-mute-button.unmute",
    );
    const timeRange =
      this.islikelive !== "true" &&
      controls &&
      controls instanceof HTMLElement &&
      controls?.querySelector(".timerange");
    if (timeRange && timeRange instanceof HTMLElement) {
      timeRange.style.display = "none";
    }
    this.addEventListener("click", () => {
      this.setAttribute("hasplayed", "true");

      if (controls && controls instanceof HTMLElement) {
        controls.style.removeProperty("display");
      }
      if (timeRange && timeRange instanceof HTMLElement) {
        timeRange.style.removeProperty("display");
      }
      if (muteButton && muteButton instanceof HTMLElement) {
        muteButton.style.removeProperty("display");
      }
      if (skipIntroButton && skipIntroButton instanceof HTMLElement) {
        skipIntroButton.style.removeProperty("display");
      }
    });
  }
  async persistMuteButtonOnHover() {
    if (!this.shadowRoot) return;

    const muteButton = await waitForElement(
      this.shadowRoot,
      ".volume-controls media-mute-button",
    );

    const volumeRange = await waitForElement(
      this.shadowRoot,
      "media-volume-range",
    );

    if (!muteButton || !volumeRange || !(volumeRange instanceof HTMLElement)) {
      return;
    }

    muteButton?.addEventListener("mouseenter", () => {
      volumeRange.setAttribute("visible", "true");
    });

    volumeRange?.addEventListener("mouseleave", () => {
      volumeRange.removeAttribute("visible");
    });

    this.addEventListener("click", (event) => {
      if (
        !volumeRange ||
        !muteButton ||
        !event.target ||
        !(event.target instanceof Node)
      ) {
        return;
      }
      if (
        !muteButton.contains(event.target) ||
        !volumeRange?.contains(event.target)
      ) {
        volumeRange.removeAttribute("visible");
      }
    });
  }

  /**
   *
   * Lifecycle methods
   */
  async connectedCallback() {
    /** @type {Partial<UserOptions>} */
    const attributes = {};
    const attributeNames = this.getAttributeNames();

    attributeNames.forEach((name) => {
      attributes[name] = this.getAttribute(name);
    });

    if (attributes.canfloat === "true") {
      const floatingObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const mediaController =
            this.shadowRoot?.querySelector("media-controller");
          if (this.canfloat && entry.isIntersecting) {
            mediaController?.classList.remove("is-floating");
          } else {
            mediaController?.classList.add("is-floating");
          }
        });
      });

      floatingObserver.observe(this);
    }
    if (!this.token) {
      console.error("A media token is required");
      return;
    }
    // @see https://caniuse.com/mdn-api_cssstylesheet_replacesync
    if (this.shadowRoot || !("replaceSync" in CSSStyleSheet.prototype)) {
      return;
    }

    const mediaItem = await getAndValidateMedia(
      attributes.host,
      attributes.token,
      // can be null undefined, string or potatoes
      // @ts-ignore
      attributes.key,
    );

    const shadowroot = this.attachShadow({ mode: "open" });

    if (!mediaItem) {
      console.error("No media item found");
      shadowroot.innerHTML = composeNoMediaHtml();
      return;
    }
    // @ts-ignore pass user options without having to list them all again
    const css =
      mediaItem.type === "Audio"
        ? composeAudioCss(attributes)
        : composeVideoCss(attributes, mediaItem);

    // @ts-ignore pass user options without having to list them all again
    const html =
      mediaItem.type === "Audio"
        ? composeAudioHtml(attributes, mediaItem)
        : composeVideoHtml(attributes, mediaItem);

    const sheet = new CSSStyleSheet();
    sheet.replaceSync(css);
    shadowroot.adoptedStyleSheets = [sheet];

    if (!this.shadowRoot) {
      return;
    }
    shadowroot.innerHTML = html;

    /** @type { HTMLMediaElement | null | undefined } */
    // @ts-ignore shadowRoot is never for some reason
    const player = this.shadowRoot?.querySelector("mux-video");

    // @ts-ignore shadowRoot is never for some reason
    const overlayButtonIntro = this.shadowRoot?.querySelector(
      'overlay-button[title="skip-intro"]',
    );
    // @ts-ignore shadowRoot is never for some reason
    const overlayButtonRecap = this.shadowRoot?.querySelector(
      'overlay-button[title="skip-recap"]',
    );

    overlayButtonIntro?.addEventListener("click", () => {
      if (player && mediaItem.media?.intro?.end) {
        player.currentTime = mediaItem.media.intro.end;
        overlayButtonIntro.style.display = "none";
      }
    });

    overlayButtonRecap?.addEventListener("click", () => {
      if (player && mediaItem.media?.recap?.end) {
        player.currentTime = mediaItem.media.recap.end;
        overlayButtonRecap.style.display = "none";
      }
    });

    player?.addEventListener("timeupdate", () => {
      const playedTime = this.getAttribute("mediatime");
      const timeStampSeconds = Math.floor(
        playedTime ? parseFloat(playedTime) : 0,
      );

      const isInRecapWindow =
        mediaItem?.media?.recap?.start &&
        timeStampSeconds > mediaItem.media.recap.start &&
        timeStampSeconds < mediaItem.media.recap.end;

      const isInIntroWindow =
        mediaItem?.media?.intro?.start !== undefined &&
        mediaItem?.media?.intro?.end !== undefined &&
        timeStampSeconds > mediaItem.media.intro.start &&
        timeStampSeconds < mediaItem.media.intro.end;

      if (isInRecapWindow) {
        overlayButtonRecap?.setAttribute("hidden", false);
      } else {
        overlayButtonRecap?.setAttribute("hidden", true);
      }
      if (isInIntroWindow) {
        overlayButtonIntro?.setAttribute("hidden", false);
      } else {
        overlayButtonIntro?.setAttribute("hidden", true);
      }
    });

    this.bindEvents(mediaItem);
    this.persistMuteButtonOnHover();
    this.showControlsOnActivate(mediaItem.type);
    this.initiateDownload(mediaItem.media?.downloads);

    if (mediaItem.status && mediaItem.status !== "Active") {
      const interval = setInterval(async () => {
        const updatedMediaItem = await getAndValidateMedia(
          attributes.host,
          // @ts-ignore token is never undefined
          attributes.token,
        );
        if (updatedMediaItem && updatedMediaItem.status === "Active") {
          const updatedHtml =
            mediaItem.type === "Audio"
              ? composeAudioHtml(this, updatedMediaItem)
              : composeVideoHtml(this, updatedMediaItem);
          if (this.shadowRoot) {
            this.shadowRoot.innerHTML = updatedHtml;
          }
          clearInterval(interval);
        }
      }, 3000);
    }
  }
}

customElements.define("media-manager-player", MediaManagerPlayer);

export default MediaManagerPlayer;
