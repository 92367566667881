const fastForwardIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' aria-hidden='true' viewBox='0 0 24 24'%3E%3Cpath d='m7.58 16.89 5.77-4.07c.56-.4.56-1.24 0-1.63L7.58 7.11C6.91 6.65 6 7.12 6 7.93v8.14c0 .81.91 1.28 1.58.82M16 7v10c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1s-1 .45-1 1'/%3E%3C/svg%3E`;

class OverlayButton extends HTMLElement {
  constructor() {
    super();
    this.title = this.getAttribute("title") ?? "";
    this.image = this.getAttribute("image") ?? fastForwardIcon;
    this.text = this.getAttribute("text") ?? "";
    this.showIcon = this.getAttribute("showIcon") ?? "false";
  }

  css() {
    return `
    button {
        all: unset;
        position: absolute;
        bottom: 4rem;
        right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        white-space: nowrap;
        padding: ${this.showIcon === "true" ? "1rem" : "1.5rem"};
        background-color: var(--zinc-90);
        font-family: sans-serif;
        font-size: var(--media-font-size);
        font-weight: var(--media-font-weight);
        border-radius: 0.2rem;
        color: var(--zinc-20);
      }
      button:hover {
        cursor: pointer;
        background-color: var(--zinc-80);
      }
      span {
        display: block;
      }
      img {
        width: 24px;
        height: 24px;
        margin: auto;
        flex-shrink: 0;
      }
    `;
  }

  connectedCallback() {
    const buttonHtml = `
          <button type="button" title=${this.title}>
            ${this.showIcon === "true" ? `<img src="${this.image}" width="24" alt="" />` : ""}
            <span>${this.text}</span>
          </button>
        `;

    const shadowroot = this.attachShadow({ mode: "open" });
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(this.css());
    shadowroot.adoptedStyleSheets = [sheet];

    if (this.shadowRoot) {
      shadowroot.innerHTML = buttonHtml;
    }
  }
}

customElements.define("overlay-button", OverlayButton);

export default OverlayButton;
