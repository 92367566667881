/**
 * Set Player Type for Mux Data
 * @param {number} playerCode player code
 * @returns {string} player type
 */
function resolvePlayerName(playerCode = 1) {
  const playerTypes = {
    1: "Advanced Embed",
    2: "Simple Embed",
    3: "Iframe Embed",
    4: "Preview Embed",
  };
  if (!playerTypes[playerCode]) {
    return playerTypes[1];
  }
  return playerTypes[playerCode];
}
export default resolvePlayerName;
