import { parse } from "@plussub/srt-vtt-parser";

/**
 * @typedef {import('../../types').ParsedResult} ParsedResults
 * @typedef {import('../../types').Entry} Entry
 */
/**
 *
 * @param {string} url - url of the chapters file
 * @returns {Promise<Entry[] | null>} - parsed chapters
 */
async function getChapters(url) {
  if (!url) {
    return null;
  }

  try {
    const data = await fetch(url);

    const chaptersText = await data.text();

    const parsedChapters = parse(chaptersText);

    return parsedChapters?.entries;
  } catch (error) {
    console.error(
      `Unable to return or parse chapters, reason: ${JSON.stringify(error)}`,
    );
    return null;
  }
}
export default getChapters;
