/**
 *
 * @param {string | null} [iconColor] - the color of the play/pause icon
 * @param {boolean} hidepause - whether to hide the pause icon
 * @returns {string} - the html string to be rendered
 */
function composePlayPauseButton(iconColor = "currentColor", hidepause = false) {
  return `
  <media-play-button id="media-play" notooltip slot="centered-chrome">
    <svg slot="play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="none" d="M0 0h20v20H0z"/><path fill="${iconColor}" d="M3.441 16.7V3.3a1.227 1.227 0 0 1 .389-.923A1.27 1.27 0 0 1 4.741 2a1.3 1.3 0 0 1 .34.049 1.351 1.351 0 0 1 .34.146l10.559 6.7a1.254 1.254 0 0 1 .437.486 1.358 1.358 0 0 1 .146.615 1.358 1.358 0 0 1-.146.615 1.254 1.254 0 0 1-.437.486l-10.559 6.7a1.347 1.347 0 0 1-.34.146 1.294 1.294 0 0 1-.34.049 1.27 1.27 0 0 1-.907-.372 1.227 1.227 0 0 1-.393-.92Z"/></svg>
    ${hidepause !== true ? `<svg slot="pause" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="none" d="M0 0h20v20H0z"/><path fill="${iconColor}" d="M14.572 18a2.2 2.2 0 0 1-1.614-.671 2.2 2.2 0 0 1-.671-1.614V4.286a2.2 2.2 0 0 1 .671-1.614 2.2 2.2 0 0 1 1.614-.671 2.2 2.2 0 0 1 1.614.671 2.2 2.2 0 0 1 .671 1.614v11.429a2.2 2.2 0 0 1-.671 1.614 2.2 2.2 0 0 1-1.614.671Zm-9.143 0a2.2 2.2 0 0 1-1.614-.671 2.2 2.2 0 0 1-.671-1.614V4.286a2.2 2.2 0 0 1 .671-1.614 2.2 2.2 0 0 1 1.614-.671 2.2 2.2 0 0 1 1.614.671 2.2 2.2 0 0 1 .671 1.614v11.429a2.2 2.2 0 0 1-.671 1.614A2.2 2.2 0 0 1 5.429 18Z"/></svg>` : "<span slot='pause'></span>"}
  </media-play-button>
`;
}

export default composePlayPauseButton;
