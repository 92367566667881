/**
 *  @typedef {import('../../types').Audio} Audio
 *  @typedef {import('../../types').Video} Video
 *  @typedef {import('../../types').Content} Content
 */

/**
 * Call API and return the response
 * @param {string} host API host
 * @param {string} token Media token
 * @param {string} [key] Media key
 * @returns {Promise<{ error: boolean, audio?: Audio, video?: Video }>} Media object
 */
async function callApi(host, token, key) {
  const withKey = key ? `?key=${key}` : "";
  const url = `${host.replace(/\/$/, "")}/api/v1/media/${token}${withKey}`;
  try {
    const response = await fetch(url, {
      cache: "no-cache",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    if (response.status >= 299) {
      throw new Error(`Unable to fetch media from API`);
    }
    if (!response.ok) {
      throw new Error("No data was returned from the API.");
    }
    return response.json();
  } catch (error) {
    if (error.request && error.request.status === 404) {
      throw new Error("This media cannot be found");
    } else {
      throw error;
    }
  }
}

/**
 *
 * @param {string} host - url where the media is hosted
 * @param {string}token - token for the media item
 * @param {string | undefined} [key] - optional media key
 * @returns {Promise< Content | null | undefined >} The video or audio object
 */
async function getAndValidateMedia(host, token, key) {
  try {
    const data = await callApi(host, token, key);
    if (!data) {
      throw new Error("Media could not be played. Is it published?");
    }
    if (data.error) {
      throw new Error(`Error: ${data.error}`);
    }
    // This saves us always having to find out if it's a video or audio. We can use the `type` property inside the media instead
    const mediaItem = {
      ...data?.video,
      ...data?.audio,
    };
    return mediaItem;
  } catch (error) {
    console.error(error.message ? `Error: ${error.message}` : error);
    return null;
  }
}
export default getAndValidateMedia;
