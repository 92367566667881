/**
 * @typedef {import('../../types').UserOptions} UserOptions
 * @typedef {import('../../types').Content} Content
 */

/**
 *
 * @param {UserOptions | any} userOptions - user settings passed into the component. Having to include `any` because there's no easy way of passing only attributes of the class without explicity stating what they are
 * @returns {string} - the html string to be rendered
 */
function composeAudioCss(userOptions) {
  return `
    :host {
      --background-color: transparent;
      --zinc-800: #27272a;
      --zinc-500: #71717a;
      --zinc-50: #f4f4f5;
      --zinc-90: rgba(0, 0, 0, 0.75);
      --media-button-icon-width: 24px;
      --primary-color: ${userOptions.overridecolor ? `${userOptions.overridecolor}` : `#0284c7`};
      display: block;
      --media-font-size: 14px;
    }
     media-controller {
      --media-control-background: var(--background-color);
      --media-control-hover-background: transparent;
      --media-text-color: var(--zinc-500);
      --media-icon-color: var(--zinc-500);
      --media-range-thumb-background: var(--primary-color);
      background-color: var(--background-color);
      --media-tooltip-background: var(--zinc-90);
      --media-range-bar-color: var(--primary-color);
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
      padding: 1rem;
      border-radius: 8px;
      display: block;
    }
    .media-container {
      padding: 16px;
      background-color: white;
      border-radius: 8px;
    }
    media-time-display {
      color: #a1a1aa;
    }
    .player-background {
      background-color: ${userOptions.showdescription ? `var(--zinc-50)` : `transparent`};
      border-radius: 8px;
    }
    .volume-controls {
      position: relative;
    }
    .volume-controls {
      position: relative;
    }
    .volume-controls media-volume-range {
      --media-range-track-background: var(--zinc-800);
      position: absolute;
      top: -5rem;
      left: -2rem;
      transform: rotate(-90deg);
      display: none;
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
    }
    media-mute-button:hover ~ media-volume-range,
    media-mute-button:focus ~ media-volume-range,
    media-volume-range:hover,
    media-volume-range:focus {
      display: block;
    }
    media-time-range {
      width: 100%;
      --media-range-track-background: var(--zinc-500);
    }
    media-time-range:hover {
      --media-range-thumb-width: 18px;
      --media-range-thumb-height: 18px;
      
    }
    media-time-display,
    media-time-range,
    media-mute-button,
    media-volume-range,
    media-share-button,
    media-settings-menu-button,
    media-cast-button,
    media-seek-backward-button,
    media-seek-forward-button {
      background-color: ${userOptions.showdescription ? `var(--zinc-50)` : `transparent`};
    }
    media-play-button,
    media-seek-backward-button,
    media-seek-forward-button,
    media-time-display,
    media-mute-button,
    media-settings-menu-button,
    media-cast-button,
    media-fullscreen-button {
      svg {
        transition: transform 0.25s;
      }
    }
    media-play-button:hover,
    media-seek-backward-button:hover,
    media-seek-forward-button:hover,
    media-time-display:hover,
    media-mute-button:hover,
    media-settings-menu-button:hover,
    media-cast-button:hover,
    media-fullscreen-button:hover {
      background: transparent;
  
      svg {
        transform: scale(1.2);
      }
    }
    ${
      userOptions.showdescription
        ? `
      div.timerange {
        padding-top: 0.5rem;
      }
    `
        : ``
    }
    .controls {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .controls > div {
      display: flex;
    }
    .video-meta {
      font-family: sans-serif;
      display: flex;
      gap: 1rem;
    }
    .video-meta img {
      width: 10%;
      align-self: flex-start;
    }
    .video-meta h1 {
      width: 90%;
      font-size: 1.25rem;
      margin-top: 0;
      margin-bottom: 0.4rem;
      font-weight: normal;
      line-height: 1.2;
      color: var(--zinc-800);
    }
    .video-meta p {
      line-height: 1.3;
      font-size: 0.875rem;
      overflow: hidden;
      margin-top: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      color: var(--zinc-500);
    }
    media-play-button {
      transform: scale(0.7);
      background-color: var(--primary-color);
      border-radius: 8px;
      display: flex;
      
      svg {
        transition: transform 0.25s;
        transform: scale(1);
      }
    }
    media-play-button:hover {
      background-color: var(--primary-color);

      svg {
        transform: scale(1.3);
      }
    }
    media-settings-menu {
      --media-control-background: rgba(255,255,255,0.6);
      z-index: 10;
      margin-right: 1rem;
      margin-bottom: 1.4rem;
    }
    media-settings-menu[hidden] {
      position: absolute;
    }
    .time-duration-display span {
      font-size: var(--media-font-size);
      color: var(--zinc-500);
      margin-left: -0.35rem;
      margin-right: -0.45rem;
    }
    .time-duration-display {
      media-time-display,
      media-duration-display {
        color: var(--zinc-500);
      }
    }
    .time-duration-display {
      display: none;
    }
    .offline-notice {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--zinc-500);
      font-size: 0.5rem;
      font-family: sans-serif;
    }
    @media(min-width: 768px) {
      .time-duration-display {
        display: block;
      }
    }
    `;
}
export default composeAudioCss;
