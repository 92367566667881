/**
 * @typedef {import('../../types').Audio} Audio
 * @typedef {import('../../types').Video} Video
 * @typedef {import('../../types').Content} Content
 * @typedef {import('../../types').ParsedResult} shares
 * @typedef {import('../../types').Entry} share
 */

const shareMethods = [
  {
    name: "X.com",
    icon: '<svg role="img" fill="currentColor" width="12px" height="12px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>X</title><path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"/></svg>',
    url: "https://www.x.com/share?u=",
  },
  {
    name: "Facebook",
    icon: '<svg role="img" fill="currentColor" width="12px" height="12px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z"/></svg>',
    url: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    name: "LinkedIn",
    icon: '<svg role="img" fill="currentColor" width="12px" height="12px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>',
    url: "https://www.linkedin.com/shareArticle?mini=true&url=",
  },
  {
    name: "WhatsApp",
    icon: '<svg role="img" fill="currentColor" width="12px" height="12px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>WhatsApp</title><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/></svg>',
    url: "https://api.whatsapp.com/send?text=",
  },
  {
    name: "Link",
    icon: '<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  width="12px" height="12px" stroke-width="1.5" stroke="currentColor" ><path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" /></svg>',
    url: "",
  },
];

/**
 *
 * @param {{ icon: string, url: string, name: string }[]} methods - share methods
 * @returns {string} - share methods
 */
function composeShareMethods(methods) {
  return methods
    .map(
      (share) => `
    <li class="share-item">
      <a class="share-link" href="${share.url}${window.location.href}">
        ${share.icon}
        <span>${share.name}</span>
      </a>
    </li>
  `,
    )
    .join("");
}

class ShareMenu extends HTMLElement {
  constructor() {
    super();
    this.shares = this.getAttribute("shares");
    this.iconColor = this.getAttribute("iconColor") ?? "currentColor";
  }

  static css = `
      div.shares {
        position: relative;
        height: 100%;
      }
      .shares-title {
        font-size: 14px;
        font-weight: 400;
        padding: 1rem 1rem;
        color: white;
        margin: 0;
      }
      hr {
        border: 0;
        border-top: 1px solid rgba(255,255,255,0.1);
        margin: 0;
      }
      button.share-button {
        all: unset;
        padding: 0 0.5rem;
        height: 100%;
        height: 100%;
        cursor: pointer;
      }
      button.share-button media-tooltip {
        display: none;
      }
      button.share-button:hover media-tooltip {
        display: block;
      }
      button.share-button svg {
        color: white;
        transition: transform 0.25s;
      }
      button.share-button:hover {
        svg {
          transform: scale(1.2);
        }
      }
      button.share-button svg {
        width: 24px;
        height: 24px;
      }
      button.share-button span {
        display: none;
      }
      button.share-button[data-isopen=true] {
        
        svg {
          transform: scale(1.2);
        }
      }
      button.share-button[data-isopen=false] ~ nav.share-menu {
        display: none;
      }
      button.share-button[data-isopen=true] ~ nav.share-menu {
        display: flex;
      }
      nav.share-menu {
        font-family: sans-serif;
        position: absolute;
        top: -280px;
        left: -40px;
        width: max-content;
        background: rgba(0,0,0,0.6);
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
      }
      nav.share-menu ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li.share-item {
        display: flex;
      }
      a.share-link {
        all: unset;
        padding: 1rem;
        display: flex;
        width: 100%;
        color: white;
        align-items: center;
        gap: .5rem;
        font-size: 14px;
      }
      a.share-link:hover {
        background-color: rgba(0,0,0,0.8);
        cursor: pointer;
      }
    `;

  async connectedCallback() {
    const shares = `
        <button class="share-button" data-isopen="false">
        <media-tooltip placement=top><slot name="tooltip-content">Share</slot></media-tooltip>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g data-name="Group 320"><path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 543"/><path fill="${this.iconColor}" d="M15 18a2.314 2.314 0 0 1-1.7-.7 2.314 2.314 0 0 1-.7-1.7 2.2 2.2 0 0 1 .02-.29 1.47 1.47 0 0 1 .06-.27l-5.64-3.28a2.633 2.633 0 0 1-.76.47 2.322 2.322 0 0 1-.88.17 2.314 2.314 0 0 1-1.7-.7A2.315 2.315 0 0 1 3 10a2.314 2.314 0 0 1 .7-1.7 2.314 2.314 0 0 1 1.7-.7 2.323 2.323 0 0 1 .88.17 2.636 2.636 0 0 1 .76.47l5.64-3.28a1.471 1.471 0 0 1-.06-.27 2.2 2.2 0 0 1-.02-.29 2.315 2.315 0 0 1 .7-1.7A2.314 2.314 0 0 1 15 2a2.314 2.314 0 0 1 1.7.7 2.315 2.315 0 0 1 .7 1.7 2.315 2.315 0 0 1-.7 1.7 2.315 2.315 0 0 1-1.7.7 2.323 2.323 0 0 1-.88-.17 2.635 2.635 0 0 1-.76-.47L7.72 9.44a1.468 1.468 0 0 1 .06.27 2.194 2.194 0 0 1 .02.29 2.194 2.194 0 0 1-.02.29 1.47 1.47 0 0 1-.06.27l5.64 3.28a2.635 2.635 0 0 1 .76-.47 2.324 2.324 0 0 1 .88-.17 2.314 2.314 0 0 1 1.7.7 2.314 2.314 0 0 1 .7 1.7 2.314 2.314 0 0 1-.7 1.7 2.314 2.314 0 0 1-1.7.7Z"/></g></svg>
          <span class="share-button-text">shares</span>
        </button>
        <nav aria-label="shares" class="share-menu">
          <h6 class="shares-title">Share:</h6>
          <hr />
          <ul>
          ${composeShareMethods(shareMethods)}
          </ul>
        </nav>
    `;

    const shadowroot = this.attachShadow({ mode: "open" });
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(ShareMenu.css);
    shadowroot.adoptedStyleSheets = [sheet];

    const div = document.createElement("div");
    div.classList.add("shares");
    div.innerHTML = shares;
    shadowroot.appendChild(div);

    this.bindEvents();
  }

  bindEvents() {
    this.addEventListener("click", () => {
      /**
       * @type {HTMLButtonElement | undefined | null}
       */
      const button = this.shadowRoot?.querySelector("button.share-button");

      if (!button) {
        return;
      }

      if (button?.dataset.isopen === "true") {
        button.dataset.isopen = "false";
      } else {
        button.dataset.isopen = "true";
      }
    });
  }
}

customElements.define("share-menu", ShareMenu);

export default ShareMenu;
